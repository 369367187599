import './Sticker.css';

function Sticker() {
  return (
    <a
      className="col d-flex justify-content-center text-decoration-none"
      href="https://namethedip-io.myshopify.com/"
    >
      <button className="btn">
        <span className="text-white">buy a sticker</span>
      </button>
    </a>
  );
}

export default Sticker;
