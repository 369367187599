// import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import { useState, useEffect } from 'react';
import './Menubar.css';
import logo from '../image/logoNoBg.svg';
import Social from './Social';
import Sticker from './Sticker';

import NTDForm from './NTDForm';

require('dotenv').config();

let web3Modal;

//select wallet provider endpoint options
const NTDAddress = '0xEEAB96006855BB9d6fD9ace5122cb59b91a8AF59';

// Wallet provider setup
const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: 'NTD',
    },
  },
};
if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    cacheProvider: false,
    providerOptions, // required
  });
}

const Menubar = () => {
  const [isWalletPresent, setIsWalletPresent] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [signer, setSigner] = useState(undefined);
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [network, setNetwork] = useState();
  const [account, setAccount] = useState();

  //check if a wallet extension is present
  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      setIsWalletPresent(true);
    }
  }, []);

  //connect to wallet
  async function connect() {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const provider = await web3Modal.connect();
        const library = new ethers.providers.Web3Provider(provider);
        const accountList = await library.listAccounts();
        const network = await library.getNetwork();
        setNetwork(network);
        setProvider(provider);
        setLibrary(library);
        setSigner(library.getSigner());
        if (accountList) setAccount(accountList[0]);
        setIsConnected(true);
      } catch (err) {
        console.error(err);
      }
    } else {
      setIsConnected(false);
    }
  }

  //clears Modal3 cache
  const refreshState = () => {
    setAccount();
    setNetwork();
    setIsConnected(false);
  };

  //clear cache at disconnect
  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    refreshState();
  };

  //listen for wallet events
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        console.log('accountsChanged', accounts);
        disconnect();
        setAccount(accounts);
      };

      const handleDisconnect = () => {
        disconnect();
      };

      const disconnect = async () => {
        await web3Modal.clearCachedProvider();
        refreshState();
      };

      provider.on('accountsChanged', handleAccountsChanged);
      provider.on('disconnect', handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged);
          provider.removeListener('disconnect', handleDisconnect);
        }
      };
    }
  }, [provider]);

  return (
    <div className="container Menubar">
      <nav className="navbar">
        <div className="row container-fluid justify-content-xs-center justify-content-sm-evenly justify-content-md-between">
          <div className=" col d-flex justify-content-center justify-content-sm-start">
            <button className="logo border-0 navbar-brand bg-transparent text-white">
              <img src={logo} alt="NameTheDip" height={90} />
            </button>
          </div>
          <Sticker />
          <div className="col d-flex justify-content-center justify-content-sm-end">
            <Social />
            {isWalletPresent ? (
              isConnected ? (
                <div className="">
                  <NTDForm
                    signer={signer}
                    NTDAddress={NTDAddress}
                    disconnect={disconnect}
                  />
                </div>
              ) : (
                <button
                  className="btn btn-outline-light d-none d-sm-block"
                  onClick={() => connect()}
                >
                  CONNECT
                </button>
              )
            ) : (
              <button className="btn btn-outline-light d-none d-sm-block">
                install a wallet
              </button>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Menubar;
